export const LOC_PLATFORM_JOB_ID_URL = process.env.LOC_PLATFORM_JOB_ID_URL;
export const ENVIRONMENT = process.env.NODE_ENV;
export const LOGIN_URL = process.env.LOGIN_URL;
export const AUTHORING_SERVICE_URL = process.env.AUTHORING_SERVICE_URL;

export const NOT_AUTHORIZED_MESSAGE =
  "Oops! you are not authorized to use this application ⛔ please contact your administrator 🛠️";
export const PAGE_NOT_ALLOWED =
  "Oops! you are not authorized to see this page ⛔ please contact your administrator 🛠️";
export const DEFAULT_MESSAGE =
  "Oops! something went wrong 🤦 please contact your administrator 🛠️";
export const NOTFOUND_MESSAGE =
  "Oops! we can't seem to find the page you're looking for 🤯";

export const VIEW_ONLY_GROUP = "lexiviewonly:*:*:*";
export const WRITE_GROUP = "lexilocalizationuser:*:*:*";
export const ADMIN_GROUP = "translator:*";
export const LEXI_USER_GROUP = "Application.LEXI.Users";
export const LOCALIZATION_STATE = "localization_req_state";

export const PAGE_CONFIG = {
  DEFAULT_PAGE: 0,
  DEFAULT_SIZE: 10,
  DEFAULT_TOTAL_ITEMS: 0,
  DEFAULT_TOTAL_PAGES: 1,
};
export const COPYJOB_ACTIONS = {
  CHANGE_SEARCH_KEYWORD: "searchData",
  CHANGE_PAGE_NUMBER: "pageNumber",
  CHANGE_PAGE_SIZE: "pageSize",
};
export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50, 100];
export const PLACEHOLDER = {
  LOCALIZATION: "Product Model, Style, or Style Color",
  PCL_DASHBOARD: "Search by Product ID",
};

export const ORDERS = {
  ASC: "ASC",
  DESC: "DESC",
};

export const COPYWRITING_SERVICE_URL = process.env.COPYWRITING_SERVICE_URL;
