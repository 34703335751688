export const ROUTES = {
  PCL_DASHBOARD: "localization",
  PRODUCT_LOCALIZATION_DETAILS: ":localizationParam",
  AUTHORING_DASHBOARD: "authoring",
  AUTHORING_DETAILS: "copy/jobs/:jobID",
  REVIEW_DASHBOARD: "review",
  REVIEW_DETAILS: "copy/jobs/:jobID",
  PRODUCT_ATTRIBUTES_DASHBOARD: "product-attributes",
  PRODUCT_ATTRIBUTES_DETAILS: ":styleParam",
  LOGIN: "login",
  LOGIN_CALLBACK: "login/callback",
  ERROR: "error",
  NOT_FOUND: "*",
  PUBLISH_DASHBOARD: "publish",
  PUBLISH_DETAILS: ":styleId",
  ADMIN_MODULE: "admin-module",
};
