import React, { memo } from "react";
import Alert from "@mui/material/Alert";
import { AlertContainer, CloseIconStyled, SnackbarAlert } from "./Toast.styled";
import { AlertToastProps, TypeOptions } from "../Types";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
const getIcon = (type: TypeOptions) => {
  switch (type) {
    case "info":
      return <CheckCircleIcon color="info" />;
    case "success":
      return <CheckCircleIcon color="success" />;
    case "warning":
      return <WarningIcon color="warning" />;
    case "error":
      return <ErrorIcon color="error" />;
    default:
      return <CheckCircleIcon color="success" />;
  }
};

const AlertToastCustomized = ({
  toastItem,
  closeAlert,
}: AlertToastProps): JSX.Element => {
  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    event?.stopPropagation();
    closeAlert();
  };

  const icon = toastItem.icon ? toastItem.icon : getIcon(toastItem.type);

  return (
    <SnackbarAlert
      anchorOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      autoHideDuration={toastItem.autoHideDuration}
      open={true}
      sx={{
        "&.MuiSnackbar-root": { position: "relative" },
      }}
      onClose={handleClose}
    >
      <AlertContainer>
        <Alert
          action={
            <CloseIconStyled
              children={
                <CloseIcon
                  fontSize="small"
                  style={{ color: "#CACACB" }}
                  onClick={handleClose}
                />
              }
            />
          }
          icon={icon}
          severity={toastItem.type}
          sx={{
            "& .MuiAlert-icon": {
              alignItems: "center",
            },
            "& .MuiAlert-action": {
              alignItems: "center",
            },
          }}
          variant="filled"
        >
          {toastItem.isNode ? toastItem.node : toastItem.data?.message}
        </Alert>
      </AlertContainer>
    </SnackbarAlert>
  );
};

export default memo(AlertToastCustomized);
