import React, { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

import { ROUTES } from "./shared/route-constants";
import {
  AllowedRolesAuthoring,
  AllowedRolesLocalization,
  AllowedRolesProductAttributes,
} from "./shared/Roles";

import { FeatureFlagsProvider } from "./context/FeatureFlagContext";
import RequireAuth from "./shared/auth/RequireAuth";
const CopyjobDashboard = lazy(() => import("./pages/CopyjobDashboard"));
const CopyjobAuthoring = lazy(() => import("./pages/CopyjobAuthoring"));
const ReviewDashboard = lazy(
  () => import("./pages/ReviewerDashboard/components/ReviewDashboard")
);
const ReviewAuthoring = lazy(
  () => import("./pages/ReviewAuthoring/components")
);
const ProductAttributesSearchProvider = lazy(
  () =>
    import("./pages/CopyjobDashboard/providers/ProductAttributesSearchProvider")
);
const ErrorHandling = lazy(() => import("./pages/Error/ErrorHandling"));
const Login = lazy(() => import("./pages/Login/Login"));
const Callback = lazy(() => import("./pages/Login/Callback"));
const NotFoundPage = lazy(() => import("./pages/NotFound/NotFoundPage"));
const ProductCopyLocalizationDashboardBase = lazy(
  () =>
    import(
      "./pages/ProductCopyLocalizationDashboard/components/ProductCopyLocalizationDashboard"
    )
);
const ProductCopyLocalizationDetails = lazy(
  () =>
    import(
      "./pages/ProductCopyLocalizationDetails/ProductCopyLocalizationDetails"
    )
);
const Layout = lazy(() => import("./components/Layout"));
const BomDetails = lazy(() => import("./pages/BomDetails/index"));
const InitiateScreen = lazy(() => import("./pages/AdminModule"));
const AuditJob = lazy(() => import("./pages/AdminModule/AuditReport"));
const ProductSearch = lazy(() => import("./pages/PublishDashboard"));
const StatusDetailsPage = lazy(
  () => import("./pages/PublishDashboard/DetailsPage")
);
const {
  PCL_DASHBOARD,
  PRODUCT_LOCALIZATION_DETAILS,
  AUTHORING_DASHBOARD,
  AUTHORING_DETAILS,
  REVIEW_DASHBOARD,
  REVIEW_DETAILS,
  PRODUCT_ATTRIBUTES_DASHBOARD,
  PRODUCT_ATTRIBUTES_DETAILS,
  LOGIN,
  LOGIN_CALLBACK,
  ERROR,
  NOT_FOUND,
  ADMIN_MODULE,
  PUBLISH_DETAILS,
  PUBLISH_DASHBOARD,
} = ROUTES;

const router = createBrowserRouter([
  {
    path: PCL_DASHBOARD,
    element: <Layout headerText="Copy Localization" />,
    errorElement: <ErrorHandling />,
    children: [
      {
        element: (
          <RequireAuth allowedGroups={AllowedRolesLocalization}>
            <ProductCopyLocalizationDashboardBase />
          </RequireAuth>
        ),
        index: true,
      },
      {
        path: PRODUCT_LOCALIZATION_DETAILS,
        element: (
          <RequireAuth allowedGroups={AllowedRolesLocalization}>
            <ProductCopyLocalizationDetails />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: AUTHORING_DASHBOARD,
    errorElement: <ErrorHandling />,
    children: [
      {
        element: (
          <RequireAuth allowedGroups={AllowedRolesAuthoring}>
            <FeatureFlagsProvider>
              <CopyjobDashboard />
            </FeatureFlagsProvider>
          </RequireAuth>
        ),
        index: true,
      },
      {
        path: AUTHORING_DETAILS,
        element: (
          <RequireAuth allowedGroups={AllowedRolesAuthoring}>
            <FeatureFlagsProvider>
              <CopyjobAuthoring />
            </FeatureFlagsProvider>
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: PRODUCT_ATTRIBUTES_DASHBOARD,
    element: <Layout />,
    errorElement: <ErrorHandling />,
    children: [
      {
        element: (
          <RequireAuth allowedGroups={AllowedRolesProductAttributes}>
            <ProductAttributesSearchProvider />
          </RequireAuth>
        ),
        index: true,
      },
      {
        path: PRODUCT_ATTRIBUTES_DETAILS,
        element: (
          <RequireAuth allowedGroups={AllowedRolesProductAttributes}>
            <BomDetails />
          </RequireAuth>
        ),
      },
    ],
  },
  {
    path: REVIEW_DASHBOARD,
    element: <Layout headerText="Review" />,
    errorElement: <ErrorHandling />,
    children: [
      {
        element: <ReviewDashboard />,
        index: true,
      },
      {
        path: REVIEW_DETAILS,
        element: <ReviewAuthoring />,
      },
    ],
  },
  {
    path: ADMIN_MODULE,
    element: <Layout headerText="Admin" />,
    errorElement: <ErrorHandling />,
    children: [
      {
        element: <InitiateScreen />,
        index: true,
      },
      {
        path: PUBLISH_DETAILS,
        element: <AuditJob />,
      },
    ],
  },
  // {
  //   path: PUBLISH_DASHBOARD,
  //   element: <Layout headerText="Admin" />,
  //   errorElement: <ErrorHandling />,
  //   children: [
  //     {
  //       element: <ProductSearch />,
  //       index: true,
  //     },
  //     {
  //       path: PUBLISH_DETAILS,
  //       element: <StatusDetailsPage />,
  //     },
  //   ],
  // },
  {
    path: LOGIN,
    element: <Login />,
  },
  {
    path: LOGIN_CALLBACK,
    element: <Callback />,
  },
  {
    path: ERROR,
    element: <ErrorHandling />,
  },
  {
    path: NOT_FOUND,
    element: <NotFoundPage />,
  },
]);

export default router;
