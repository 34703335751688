import { Icon } from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import { styled } from "@mui/system";
type Props = {
  open: boolean;
};
export const SnackbarAlert = styled(Snackbar)`
  display: flex;
  .MuiPaper-root {
    border-radius: 24px;
    background-color: rgba(31, 31, 33, 0.9);
  }
`;

export const AlertContainer = styled("div")({
  minWidth: "100%",
  marginBottom: "5px",
});

export const ToastContainerStyled = styled("div")<Props>`
  float: left;
  overflow: auto;
  display: flex;
  flex-direction: column;
  z-index: ${(props) => (props.open ? 200 : -1)};
  position: fixed;
  min-height: 200px;
  padding: 4px;
  width: 400px;
  box-sizing: border-box;
  top: 16px;
  left: 50%;
  transform: translateX(-50%);
`;

export const CloseIconStyled = styled(Icon)`
  top: -7px;
  position: absolute;
  right: -4px;
  background-color: #39393b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
